import Foundation from '../scripts/foundation';
const bodyEl = $('body');
const headerEl = $('header');
const showSubmenuBtn = $('.show-submenu');
const searchIcon = $('.search_icon');
const searchWrapper = $('.search_wrapper');
const langIcon = $('.lang');
const langList = $('.languages-dropdown__list');
const mainMenuContainer = $('.main-menu-container');
const redLineEl = $('.header_red_line');

/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('is-active');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
  });

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if (window.innerWidth < 641) {
      if ($('.menu-icon').hasClass('is-active')) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }

      if (headerEl.hasClass('active-burger')) {
        removeHeaderClass();
      }

      if (bodyEl.hasClass('lock')) {
        bodyEl.removeClass('lock');
      }

      closeHeaderSearch();
      closeHeaderLanguage();
      hiddenRedLine();
    }
  }, 200);
});

$(window)
  .on('load changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.is('large')) {
      setTimeout(function () {
        $('.submenu-wrapper__inner .menu').each((idx, el) => $(el).addClass('submenu'));
      }, 10);

      if (bodyEl.hasClass('lock')) {
        bodyEl.removeClass('lock');
      }

      if (headerEl.hasClass('active-burger')) {
        removeHeaderClass();
      }

      if (showSubmenuBtn.hasClass('is-active')) {
        showSubmenuBtn.removeClass('is-active');
      }

      if ($('.menu-item > a').hasClass('active-mobile-red-line')) {
        $('.menu-item > a').removeClass('active-mobile-red-line');
      }

      closeHeaderSearch();
      closeHeaderLanguage();
      hiddenRedLine();
    }
  })
  .on('changed.zf.mediaquery', function () {
    if (headerEl.hasClass('active-burger')) {
      removeHeaderClass();
    }
  });

$('.burger-menu').on('click', function () {
  bodyEl.toggleClass('lock');
  headerEl.toggleClass('active-burger');
});

showSubmenuBtn.on('click', function () {
  $(this).next('.menu').slideToggle();
  $(this).closest('li').find('> a').toggleClass('active-mobile-red-line');

  showSubmenuBtn.not(this).removeClass('is-active').next('.menu').slideUp();
  showSubmenuBtn
    .not(this)
    .closest('li')
    .find('> a')
    .removeClass('active-mobile-red-line');
});

$(window).on('scroll load', () => {
  $('.header').toggleClass('is-scroll', $(window).scrollTop() > 50);
});

function showSubMenu() {
  $(document).on('click', '.header .dropdown > li > a', function (e) {
    if ($(e.target).next('.submenu-wrapper').length > 0) {
      e.preventDefault();
    }
    const subMenu = $(e.target).next();
    const elShowSubmenu = $('.show_submenu');

    fixClosingSubmenuAnimation($('.search_wrapper.show_search'));
    closeHeaderSearch();
    closeHeaderLanguage();

    //fix closing submenu animation.
    if (elShowSubmenu.not(subMenu).length > 1) {
      let subMenuThatWillClosed = elShowSubmenu.not(subMenu).eq(1);

      fixClosingSubmenuAnimation(subMenuThatWillClosed);
    }

    elShowSubmenu.not(subMenu).removeClass('show_submenu');
    headerEl.removeClass('white-header');
    $('.search_open').removeClass('search_open');

    if (subMenu.hasClass('show_submenu')) {
      subMenu.removeClass('show_submenu');
      headerEl.removeClass('white-header');
      $(e.target).removeClass('show_submenu');
    } else {
      if ($(e.target).closest('.menu-item-has-children').length > 0) {
        $(e.target).addClass('show_submenu');
      }
      subMenu.addClass('show_submenu');
      headerEl.addClass('white-header');
    }
  });

  $(document).on('click', function (e) {
    const $header = $('.header');
    if (!$header.is(e.target) && $header.has(e.target).length === 0) {
      $('.show_submenu').removeClass('show_submenu');
      headerEl.removeClass('white-header');
      closeHeaderSearch();
      closeHeaderLanguage();
    }
  });

  searchIcon.on('click', function () {
    if (searchWrapper.hasClass('show_search')) {
      closeHeaderSearch();
    } else {
      closeHeaderLanguage();
      searchWrapper.addClass('show_search');
      mainMenuContainer.addClass('search_open'); //for Mobile
      searchIcon.find('.action_btn__icon').addClass('search_open');
      headerEl.addClass('white-header');
      fixClosingSubmenuAnimation($('.submenu-wrapper.show_submenu'));
      $('.show_submenu').removeClass('show_submenu');
    }
  });

  langIcon.on('click', function () {
    if (langList.hasClass('show_language')) {
      closeHeaderLanguage();
    } else {
      closeHeaderSearch();
      langList.addClass('show_language');
      mainMenuContainer.addClass('show_language'); //for Mobile
      langIcon.find('.action_btn__icon').addClass('show_language');
      headerEl.addClass('white-header');
      fixClosingSubmenuAnimation($('.submenu-wrapper.show_submenu'));
      $('.show_submenu').removeClass('show_submenu');
    }
  });

  searchWrapper.on('click', (e) => e.stopPropagation());
}

showSubMenu();

function removeHeaderClass() {
  headerEl.removeClass('active-burger');
}

function moveRedLineOnHover() {
  //move readline
  // $('header .menu-item-has-children > a').on('mouseenter', moveLine);
  $('header .header-menu > li > a').on('mouseenter', moveLine);
  $('header .action_btn .action_btn__icon').on('mouseenter', moveLine);

  //hidden readline
  $(document).on('mousemove', function (e) {
    const a = !$(e.target).closest('.header-menu > li >').length;
    const b = !$(e.target).closest('.action_btn').length;
    const c = !$(e.target).closest('.search_wrapper').length;

    if (a && b && c) {
      hiddenRedLine();
    }
  });

  function moveLine(e) {
    const targetEl = $(e.target);
    const targetElWidth = targetEl.innerWidth();
    const targetElXPosition = targetEl.offset().left;

    redLineEl.css('opacity', '1');
    redLineEl.css('width', targetElWidth);
    redLineEl.css('left', targetElXPosition);
  }
}

moveRedLineOnHover();

function hiddenRedLine() {
  redLineEl.css('opacity', '0');
}

function closeHeaderSearch() {
  if (searchWrapper.hasClass('show_search')) {
    searchWrapper.removeClass('show_search');
    mainMenuContainer.removeClass('search_open');
    searchIcon.find('.action_btn__icon').removeClass('search_open');
    searchIcon.find('input').val('');
    headerEl.removeClass('white-header');
    $('.show_submenu').removeClass('show_submenu');
  }
}

function closeHeaderLanguage() {
  if (langList.hasClass('show_language')) {
    langList.removeClass('show_language');
    mainMenuContainer.removeClass('show_language');
    langIcon.find('.action_btn__icon').removeClass('show_language');
    headerEl.removeClass('white-header');
    $('.show_submenu').removeClass('show_submenu');
  }
}

function fixClosingSubmenuAnimation(element) {
  element.css('display', 'none');

  setTimeout(function () {
    element.css('display', 'flex');
  }, 5);
}
