function PopupCards() {
  const sections = $('.popup_cards_section');
  if (sections.length === 0) return false;

  sections.each(function (_, section) {
    const cards = $(section).find('.card');

    cards.each(function (_, card) {
      $(card).on('click', function () {
        const popup = $(this).find('.card_popup');
        $('body').addClass('lock');
        popup.addClass('open');
      });
    });
  });

  $(document).on('click', function (e) {
    if ($(e.target).closest('.card_popup__close').length > 0) {
      closePopup();
    }

    if ($(e.target).hasClass('card_popup__body') || $(e.target).hasClass('card_popup')) {
      closePopup();
    }
  });

  function closePopup() {
    $('.card_popup').removeClass('open');
    $('body').removeClass('lock');
  }
}
PopupCards();
